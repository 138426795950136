if (jQuery(".wpb-slider .slick").length) {
	jQuery(".wpb-slider .slick").slick({
		infinite: true,
		dots: true,
		fade: true,
		speed: 700,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
	});
}
