if (jQuery(".wpb-sliderrond .slick").length) {
	jQuery(".wpb-sliderrond .slick").slick({
		infinite: true,
		dots: false,
		arrows: false,
		fade: true,
		speed: 700,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
	});
}
