function initResize() {
	minHeight();
}

function init() {
	initResize();

	//BEFORE
	addClassToBody();

	jQuery("#backtotop").click(function () {
		jQuery("body,html").animate(
			{
				scrollTop: 0,
			},
			800
		);
	});

	if (jQuery(".autoheight").length) {
		jQuery(".autoheight").matchHeight({ property: "min-height" });
	}
	if (jQuery(".auto_desc").length) {
		jQuery(".auto_desc").matchHeight({ property: "min-height" });
	}
	if (jQuery(".auto_util").length) {
		jQuery(".auto_util").matchHeight({ property: "min-height" });
	}

	var offsetAncor = 50;
	if (location.hash && location.hash != "#respond") {
		setTimeout(function () {
			window.scrollTo(0, 0);
		}, 1);
		setTimeout(function () {
			var id = window.location.hash;
			if (jQuery(id).length) {
				var offset = jQuery(id).offset().top - offsetAncor;
				jQuery("html, body").animate(
					{
						scrollTop: offset,
					},
					"slow"
				);
			}
		}, 1000);
	}

	jQuery("a[href*='#']:not([href='#'])").click(function (e) {
		if (
			location.hostname == this.hostname &&
			this.pathname.replace(/^\//, "") ==
				location.pathname.replace(/^\//, "") &&
			this.hash != "#respond"
		) {
			e.preventDefault();

			var anchor = jQuery(this.hash);
			anchor = anchor.length
				? anchor
				: jQuery("[name=" + this.hash.slice(1) + "]");
			if (anchor.length) {
				jQuery("html, body").animate(
					{
						scrollTop: anchor.offset().top - offsetAncor,
					},
					1000,
					function () {
						if (window.innerWidth < 767) {
							if (jQuery(".site-navigation").hasClass("expanded")) {
								jQuery(".site-navigation").removeClass("expanded").slideUp(250);
								jQuery(".nav-trigger span").removeClass("open");
							}
						}
					}
				);
			}
		}
	});

	var hamburgers = document.querySelectorAll(".hamburger");
	if (hamburgers.length) {
		forEach(hamburgers, function (hamburger) {
			hamburger.addEventListener(
				"click",
				function () {
					this.classList.toggle("is-active");
					this.closest("header")
						.querySelector("nav")
						.classList.toggle("is-open");
					if (this.closest("header").querySelector("nav").style.maxHeight) {
						this.closest("header").querySelector("nav").style.maxHeight = null;
					} else {
						this.closest("header").querySelector("nav").style.maxHeight =
							this.closest("header").querySelector("nav").scrollHeight + "px";
					}
				},
				false
			);
		});
	}

	// jQuery(".front-page-effect-img").each(function () {
	// 	//if statement here
	// 	// use $(this) to reference the current div in the loop
	// 	//you can try something like...

	// 	if (condition) {
	// 	}
	// });

	/* Set Interval to rotate */
	var slideCount = jQuery(".front-page-effect-img").length;
	var slideList = jQuery(".front-page-effect-img");
	slideList.eq(0).addClass("active");

	//   rotateSlider.sliderElement = $('.rotate-slider');
	var currentRotation = 1;
	var rotateInterval = window.setInterval(function () {
		slideList.removeClass("active");
		slideList.eq(currentRotation).addClass("active");
		currentRotation = currentRotation + 1;
		if (currentRotation == slideCount) {
			currentRotation = 0;
		}
	}, 6000);

	// (function ($) {
	// 	//var menu = 'nav-primary';
	// 	$(".ul-primary > li.menu-item-has-children").click(function (e) {
	// 		if ($(window).width() < 992) {
	// 			e.preventDefault(); // this line prevents changing to the URL of the link href
	// 			e.stopPropagation(); // this line prevents the link click from bubbling
	// 			if (jQuery(this).hasClass("expanded")) {
	// 				jQuery(this).removeClass("expanded");
	// 			} else {
	// 				jQuery(this).addClass("expanded");
	// 			}
	// 			var $el = $("ul", this);
	// 			$(".ul-primary > li > ul").not($el).slideUp();
	// 			$el.stop(true, true).slideToggle(400);
	// 		}
	// 	});
	// 	$(".ul-primary > li > ul > li").click(function (e) {
	// 		if ($(window).width() < 992) {
	// 			e.stopImmediatePropagation();
	// 		}
	// 	});
	// 	if ($(window).width() < 992) {
	// 		$(".ul-primary > li > ul").slideUp();
	// 	}
	// })(jQuery);

	// var popupsTrigger = document.querySelectorAll(".popup-trigger, .popup-close");
	// if (popupsTrigger.length) {
	// 	forEach(popupsTrigger, function (popupTrigger) {
	// 		popupTrigger.addEventListener(
	// 			"click",
	// 			function () {
	// 				var popup = document.querySelector("." + popupTrigger.dataset.popup);
	// 				popup.classList.toggle("open");
	// 			},
	// 			false
	// 		);
	// 	});
	// }
}
