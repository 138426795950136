if (jQuery(".wpb-actualites").length) {
	jQuery(".wpb-actualites-items").slick({
		infinite: true,
		dots: true,
		slidesToShow: 2,
		slidesToScroll: 2,

		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					arrows: false,
					centerPadding: "70px",
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					arrows: false,
				},
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	});
}
